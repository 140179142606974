import React, { useEffect, useState, useCallback, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";

import { apiClient } from "../utils/apiClient";

import { svgs, colors, fontSizes } from "./Tokens";

import { SubTitle } from "./Title";
import { InputField } from "./InputField";
import { SelectFieldSecondary } from "./SelectField";
import {
  SecondaryButton,
  InvertSecondaryButtonPagination,
} from "./SecondaryButton";
import { StyledButtonContainer } from "./Button";

import { Container } from "./Container";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { IconCircle } from "./IconCircle";

import parse from "html-react-parser";
import ProgressBar from "./ProgressBar";

const ProductList = styled.div`
  .Toastify__progress-bar {
    background-color: ${colors.pink};
  }
  .Toastify__toast--success {
    color: ${colors.purple};
  }
  .Toastify__toast-icon svg {
    fill: ${colors.pink};
  }
  width: 100%;
  border-radius: 14px;
  padding-bottom: 12rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease-in-out;
  .sort-label {
    font-weight: 400;
    margin-right: 1rem;
    display: none;

    @media screen and (min-width: 768px) {
      display: inline;
    }
  }
`;

const ProductListItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2.4rem;
  margin-bottom: 1.2rem;
  padding: 2rem;
  border-top: 2px solid ${colors.purple}70;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.02);

  background-color: white;
  border-radius: 4px;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }

  > div {
    display: block;
    flex: 1;

    &.product__details {
      flex: 2;
      font-size: 1.4rem;
      max-width: 33%;
      @media screen and (min-width: 480px) {
        max-width: none;
      }
      strong {
        color: ${colors.purple};
        font-weight: 600;
      }
    }
    &.product__price {
      font-size: 1.2rem;
      opacity: 0.9;
    }
    &.product__form {
      text-align: right;
      display: flex;

      > div {
        margin: 0;
        position: static;
      }

      input,
      button {
        height: 40px;
        font-size: 16px;
        padding: 0 1.2rem;
        margin: 0;
        text-align: center;
      }

      button {
        white-space: nowrap;
        margin-left: 1rem;
        border-width: 2px;
      }

      input {
        border: 2px solid ${colors.lightGrey};
        border-radius: 6px;
        transition: border-color 0.2s ease;

        &:focus {
          border-color: ${colors.purple};
          outline: none;
        }
      }

      button {
        border-radius: 6px;
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
`;

const DesktopNone = styled(Container)`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const MobileNone = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const PortalProductListing = ({
  title,
  button,
  searchTerm,
  brands,
  cats,
  ...props
}) => {
  const [products, setProducts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [click, clickOnMore] = useState(false);
  const [page, goToNextPage] = useState(0);

  const prodTotal = useRef(0);
  const prodViewed = useRef(0);
  const disablePrevButton = useRef(false);
  const disableNextButton = useRef(false);

  const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
    useAuth0();

  const checkLoginPrice = async (productList) => {
    const updatedProducts = productList.map((product) => {
      let discount = 1 - parseFloat(product.korting.replace("%", "")) / 100;

      //Set discount to 0 for specific users
      if (
        user?.sub === "auth0|67b5c4b5f2350943141190b7" ||
        "auth0%7C67b5c4b5f2350943141190b7"
      ) {
        discount = 0;
      }

      const originalPrice = parseFloat(product.brutoprijs.replace(",", "."));
      const discountedPrice = (1 - discount) * originalPrice;

      if (isAuthenticated) {
        return {
          ...product,
          brutoprijs: discountedPrice.toLocaleString("nl-NL", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        };
      } else {
        return {
          ...product,
          brutoprijs: product.brutoprijs.replace(".", ","),
          korting: "0%",
        };
      }
    });

    setProducts(updatedProducts);
  };

  const progressBar = (total, page, chunk) => {
    page = page + 1;
    prodTotal.current = total;

    if (prodViewed.current >= prodTotal.current) {
      prodViewed.current = chunk * page;
      disableNextButton.current = true;
    } else {
      prodViewed.current = chunk * page;
      disableNextButton.current = false;
    }
    if (prodViewed.current > prodTotal.current) {
      disableNextButton.current = true;
    } else {
      disableNextButton.current = false;
    }
    if (page === 1) {
      disablePrevButton.current = true;
    } else {
      disablePrevButton.current = false;
    }
  };

  useEffect(() => {
    async function getProducts() {
      if (isLoading) return; // Wait until Auth0 is fully loaded

      let userId = user?.sub;

      const filterRes = await apiClient.portalProducts.list(
        userId,
        searchTerm,
        brands,
        cats,
        page
      );
      let productList = await filterRes.data;

      const pagination = await filterRes.page;

      progressBar(pagination.totalElements, pagination.number, pagination.size);

      setLoaded(true);
      if (!click && page !== 0) {
        goToNextPage(0);
      }
      clickOnMore(false);
      checkLoginPrice(productList);
    }

    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    brands.length,
    cats.length,
    page,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortProducts = useCallback((prods, key) => {
    let sorted = [...prods].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      if (key === "brutoprijs") {
        // Parse prices correctly, removing commas
        const aNum = parseFloat(aValue.replace(".", "").replace(",", "."));
        const bNum = parseFloat(bValue.replace(".", "").replace(",", "."));
        return bNum - aNum; // Descending order
      } else if (isNaN(aValue) || isNaN(bValue)) {
        // Handle string comparison
        return aValue.localeCompare(bValue);
      } else {
        // Handle numeric comparison for other numeric fields
        return parseFloat(aValue) - parseFloat(bValue);
      }
    });
    setProducts(sorted);
  }, []);

  const handleLoadMore = (bool, change) => {
    clickOnMore(bool);
    if ((change < 0 && page > 0) || change > 0) {
      goToNextPage((page) => page + change);
    }
  };

  if (!loaded) {
    return <Skeleton count={10} height={100} borderRadius={"7px"} />;
  } else
    return (
      <>
        <ProductList>
          <ToastContainer autoClose={3000} position="bottom-left" />
          <SubTitle
            style={{
              height: "6rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              overflow: "visible",
            }}
          >
            <h2
              style={{ margin: "0", fontSize: "2.4rem", color: colors.purple }}
            >
              Producten
            </h2>
            <DesktopNone>
              <IconCircle
                onClick={() => props.functions.openFilter()}
                color="grey"
                style={{
                  marginTop: "6px",
                  marginLeft: "25px",
                  cursor: "pointer",
                }}
              >
                {parse(svgs.filter)}
              </IconCircle>
            </DesktopNone>
            <MobileNone>
              <span
                style={{
                  marginLeft: "12px",
                  color: colors.purple,
                  fontSize: "1.8rem",
                }}
                className="sort-label"
              ></span>
            </MobileNone>
            <div style={{ color: colors.purple, fontSize: fontSizes.xsmall }}>
              <span className="sort-label">Sorteren op:</span>
              <SelectFieldSecondary
                options={[
                  { label: "Prijs", value: "brutoprijs" },
                  { label: "Naam", value: "omschrijving" },
                  { label: "Model", value: "id" },
                ]}
                onChange={(e) => sortProducts(products, e.value)}
              />
            </div>
          </SubTitle>
          {products.map((row, index) => {
            return (
              <ProductListItem key={index}>
                <div className="product__details">
                  <strong>Modelnummer:</strong> {row.id} <br />
                  <strong>Merk:</strong> {row.merk}
                  <SubTitle style={{ fontSize: "1.5rem" }}>
                    {row.omschrijving}
                  </SubTitle>
                </div>
                <div className="product__price">
                  <SubTitle
                    style={{ fontSize: "1.6rem", color: colors.purple }}
                  >
                    &euro;{row.brutoprijs}
                  </SubTitle>
                  Excl. BTW
                </div>
                <div className="product__form">
                  <InputField
                    defaultValue={1}
                    color="white"
                    type="number"
                    appearance="none"
                    style={{ margin: 0, minWidth: "4rem" }}
                  />
                  <SecondaryButton
                    onClick={(e) => [
                      props.functions.add(
                        row,
                        e.target.previousSibling.firstElementChild.value
                      ),
                      (e.target.previousSibling.firstElementChild.value = 1),
                      toast.success("Artikel toegevoegd"),
                    ]}
                  >
                    Voeg toe
                  </SecondaryButton>
                </div>
              </ProductListItem>
            );
          })}
        </ProductList>
        <StyledButtonContainer>
          <InvertSecondaryButtonPagination
            size="small"
            color="white"
            disabled={disablePrevButton.current}
            onClick={() => {
              handleLoadMore(true, -1);
            }}
          >
            Vorige
          </InvertSecondaryButtonPagination>
          <ProgressBar
            prodViewed={prodViewed.current}
            prodTotal={prodTotal.current}
          />
          <InvertSecondaryButtonPagination
            size="small"
            color="white"
            disabled={disableNextButton.current}
            onClick={() => {
              handleLoadMore(true, 1);
            }}
          >
            Volgende
          </InvertSecondaryButtonPagination>
        </StyledButtonContainer>
      </>
    );
};

export { PortalProductListing };
